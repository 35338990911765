
    /* background-color: rgb(22,71,52);
    background-color: linear-gradient(144deg, rgba(22,71,52,1) 37%, rgba(39,126,92,1) 100%); */
.calpoly{
    font-family: utopia;
}

.footer-body-small{
    font-family: Source Sans Pro, sans-serif;
    font-family: Lato!important;
    font-size: 20px!important;
    font-style: normal!important;
     font-variant: normal!important; 
     font-weight: 400 !important;
     line-height: 20px !important;
}


    /* font-family: Lato!important; 
    font-size: 24px!important; 
    font-style: normal!important; 
    font-variant: normal!important; 
    font-weight: 700!important; 
    line-height: 26.4px!important; */

.specialfooter-sub{
    font-family: Lato!important;
    font-size: 20px!important;
    font-style: normal!important;
     font-variant: normal!important; 
     font-weight: 400 !important;
     line-height: 20px !important;
}

.footer-body-large{
    font-weight: 520 !important;
    font-size: 44px;
}

.specialfooter{
    font-size: 0.95rem;
    text-transform: uppercase;
    font-weight: 400 !important;
    padding: 3px 4px;
    border-color: #c69214;
    text-align: center;
    
}
